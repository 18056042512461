<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo class="d-none d-lg-block" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5  "
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <Locale
          class="mt-1"
          style="position:absolute; top:0"
        />
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto mt-5"
        >
          <b-card-title
            class="text-center mb-2"
            title-tag="h2"
          >
            {{ $t('crypto_Cabin_Title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login_page_description') }}
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="submit"
            >
              <!-- email -->
              <b-form-group
                :label="$t('email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="abc@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('password') }}</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>
                      {{ $t('forgot_password') }}
                    </small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <div
                  dir="ltr"
                  class="google-captcha"
                >
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="sitekey"
                    :load-recaptcha-script="true"
                    :theme="$store.state.appConfig.layout.skin == 'dark'?'dark':''"
                    @expired="onCaptchaExpired"
                    @verify="onVerify"
                  />
                </div>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="rememberMe"
                  name="checkbox-1"
                >
                  {{ $t('Remember Me') }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="!pending"
                type="submit"
                variant="primary"
                block
                :disabled="invalid || !form.recaptcha"
              >
                {{ $t('Sign in') }}
              </b-button>
              <b-button
                v-if="pending"
                variant="primary"
                block
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('New on our platform?') }}</span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;{{ $t('create_account') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'
import logo from '@/views/components/logo.vue'
import store from '@/store'
import { changeLang } from '@/auth/utils'
import Locale from '@/layouts/components/Locale.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    logo,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    VueRecaptcha,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      language: process.env.VUE_APP_DEFAULT_LANGUAGE,
      rememberMe: !!localStorage.getItem('Remail'),
      form: {
        email: localStorage.getItem('Remail') || '',
        password: '',
        recaptcha: null,
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      email,
      pending: false,
      options: [
        {
          title: 'English',
          locale: 'en',
          url: require('@/assets/images/flags/en.png'),
        },
        {
          title: 'Farsi',
          locale: 'fa',
          url: require('@/assets/images/flags/IR.png'),
        },
        {
          title: 'Arabic',
          locale: 'ar',
          url: require('@/assets/images/flags/AR.png'),
        },
        {
          title: 'China',
          locale: 'cn',
          url: require('@/assets/images/flags/CN.png'),
        },
        {
          title: 'Czech',
          locale: 'cs',
          url: require('@/assets/images/flags/cs.png'),
        },
        {
          title: 'Russia',
          locale: 'ru',
          url: require('@/assets/images/flags/RU.png'),
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  created() {
    if ('activated' in this.$route.query) {
      this.$swal({
        title: this.$t('success_activation'),
        icon: 'success',
        timer: 2500,
        showConfirmButton: false,
      })
    } else if ('notactivated' in this.$route.query) {
      this.$swal({
        title: this.$t('failed_activation'),
        icon: 'warning',
        timer: 3000,
        showConfirmButton: false,
      })
    }
  },
  methods: {
    ...mapActions(['login']),
    changeLang(val) {
      changeLang(val.locale)
    },
    async submit() {
      this.pending = true
      this.login(this.form).then(response => {
        localStorage.setItem('email', this.form.email)
        if (this.rememberMe) {
          localStorage.setItem('Remail', this.form.email)
        } else {
          localStorage.removeItem('Remail')
        }
        if (response.type === 'has Two Factor') {
          this.$swal({
            title: this.$t('two_factor_message'),
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          setTimeout(async () => {
            console.log(`/${response.lang}/login-section2${this.$route.query.redirect ? `?redirect=${this.$route.query.redirect}` : ''}`)
            this.$router.push(`/${response.lang}/login-section2${this.$route.query.redirect ? `?redirect=${this.$route.query.redirect}` : ''}`)
          }, 3000)
        } else if (response.type === 'login') {
          this.$swal({
            title: this.$t('login_message'),
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          setTimeout(async () => {
            await this.$router.push(`${this.$route.query.redirect ? `${this.$route.query.redirect}` : `/${response.lang}/dashboard`}`)
          }, 3000)
        }
      }).finally(() => {
        this.pending = false
        this.onCaptchaExpired()
      })
    },
    onVerify(response) {
      this.form.recaptcha = response
    },
    async onCaptchaExpired() {
      this.$refs.recaptcha.reset()
      this.form.recaptcha = null
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';

  .language-image {
  width: 22px;
  aspect-ratio: auto 22 / 14;
  height: 14px;
}

.auth-wrapper #dropdown-grouped::marker {
  content: '';
}

</style>
